import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { InputGroup } from 'react-bootstrap';
import SchoolAdresInfo from './SchoolAdresInfo';
import phoneIcon from "../../../assets/images/icons/phone.png";


export default function SchoolBasicInfo(prop) {
    return <div>
        <fieldset disabled={!prop.editable || prop.isReadOnly}>
            <Form.Row>
                <Col xs={5}>
                    <Form.Group controlId="formFirstName">
                        <Form.Label>Schoolnaam</Form.Label>
                        <Form.Control type="text" placeholder="Schoolnaam" defaultValue={prop ? prop.name : null} name='name' />
                    </Form.Group>
                </Col>
          
                <Col>

                    <Form.Group controlId="SchoolType">
                        <Form.Label>Schooltype</Form.Label>
                        <Form.Control as="select" defaultValue={prop ? prop.school_type : null} name="school_type">
                            <option value="">Kies...</option>
                            <option value="1">Basisschool</option>
                            <option value="2">Middelbare school</option>
                            <option value="3">Basis school NT2</option>
                            <option value="4">Middelbare school NT2</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="SchoolType">
                        <Form.Label>Regio</Form.Label>
                        <Form.Control as="select" defaultValue={prop ? prop.region : null} name="region">
                            <option value="">Kies...</option>
                            <option value="1">Amsterdam</option>
                            <option value="10">Drenthe</option>
                            <option value="12">Flevoland</option>
                            <option value="2">Friesland</option>
                            <option value="4">Gelderland</option>
                            <option value="3">Groningen</option>
                            <option value="5">Limburg</option>
                            <option value="6">Midden-Nederland</option>
                            <option value="7">Noord-Brabant</option>
                            <option value="8">Noord-Holland</option>
                            <option value="11">Overijssel</option>
                            <option value="13">Utrecht</option>
                            <option value="14">Zeeland</option>
                            <option value="9">Zuid-Holland</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group controlId="Website">
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="text"  placeholder="school.nl" defaultValue={prop ? prop.website : null} name="website" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Label>Telefoonnummer 1</Form.Label>
                    <Form.Group controlId="formPhone">
                        <InputGroup>
                            {prop.telefoonnummer ? 
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <a href={`tel:${prop.telefoonnummer ? prop.telefoonnummer : null}`}>
                                            <img height="20px" width="20px" src={phoneIcon} />
                                        </a>
                                    </InputGroup.Text>
                                </InputGroup.Prepend> 
                                : null}
                            <Form.Control type="phone" placeholder="Vul telefoonnummer in" defaultValue={prop ? prop.telefoonnummer : null} name='telefoonnummer' maxLength={20} />
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Label>Telefoonnummer 2</Form.Label>
                    <Form.Group controlId="formPhone">
                        <InputGroup>
                            {prop.telefoonnummer2 ? 
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <a href={`tel:${prop.telefoonnummer2 ? prop.telefoonnummer2 : null}`}>
                                            <img height="20px" width="20px" src={phoneIcon} />
                                        </a>
                                    </InputGroup.Text>
                                </InputGroup.Prepend> 
                                : null}
                            <Form.Control type="phone" placeholder="Vul telefoonnummer in" defaultValue={prop ? prop.telefoonnummer2 : null} name='telefoonnummer2' maxLength={20} />
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Form.Row>

            <SchoolAdresInfo editable={prop.editable} isReadOnly={prop.isReadOnly} straat={prop.straat} huisnummer={prop.huisnummer} toevoegingen={prop.toevoegingen} postcode={prop.postcode} woonplaats={prop.woonplaats} />
        </fieldset>
    </div >
}