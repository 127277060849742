import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import displayToast from '../../helperFunctions/toasts';

let renderedCoordinators = 0;

export default function SchoolCoordinatorAdd(prop) {
    let [coordinators, setCoordinators] = useState();
    let [coordinatorsDivs, setCoordinatorsDivs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toDelete, setToDelete] = useState(null);

    useEffect(() => {
        if (prop.editable)
            getAllCoordinators().then(res => {
                setCoordinators(res.data);
                setLoading(true);
            })
    }, [prop.editable])

    useEffect(() => {
        let rows = prop.coordinators.map((coord, i) => {
            renderedCoordinators++;
            let tmp = renderedCoordinators;
            return (<div id={`gekoppelde_coordinators[${renderedCoordinators}]`}><h3>Gekoppelde coördinator #{i + 1}</h3>
                {prop.editable ? <Button onClick={() => setToDelete(tmp)}>Verwijderen</Button> : null}
                <Form.Group controlId="beschikbaarheid">
                    <Form.Label>Koppel coördinator</Form.Label>
                    <Form.Control as="select" defaultValue="Kies..." name="gekoppelde_coordinators[]" disabled={!prop.editable}>
                        {(loading && coordinators) ? coordinators.map(newcoord => { return <option value={newcoord.id} selected={coord.id === newcoord.id}>{newcoord.first_name} {newcoord.last_name}</option>; }) : null}
                    </Form.Control>
                </Form.Group>
                </div>)
        });
        setCoordinatorsDivs(rows);
    }, [loading, coordinators, prop.coordinators, prop.editable])

    useEffect(() => {
        if (toDelete !== null) {
            removeCoord(toDelete);
            setToDelete(null);
        }
    }, [toDelete]);

    function removeCoord(idx) {
        setCoordinatorsDivs([...coordinatorsDivs].filter((div) => div.props.id !== `gekoppelde_coordinators[${idx}]`));
    }

    async function getAllCoordinators() {
        return axios.get(`${process.env.REACT_APP_API_URL}/scholen/coordinators`, { withCredentials: true })
            .catch((error, response) => {
                let errors = JSON.stringify(error.response.data);
                displayToast(`${errors}\n Server onbereikbaar. ${error})`);
            })
    }

    function renderContactAdd(extra) {
        let rows = [...coordinatorsDivs];
        for (var i = 0; i < extra; i++) {
            renderedCoordinators++;
            let tmp = renderedCoordinators;
            // note: we are adding a key prop here to allow react to uniquely identify each
            // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
            rows.push(<div id={`gekoppelde_coordinators[${renderedCoordinators}]`} key={`add-${renderedCoordinators}`}> <h2>Nieuwe coördinator koppelen</h2>
                <Button onClick={() => setToDelete(tmp)}>Verwijderen</Button>
                <Form.Group controlId="beschikbaarheid">
                <Form.Label>Koppel coördinator</Form.Label>
                <Form.Control as="select" defaultValue="" name="gekoppelde_coordinators[]">
                    <option value="">Kies...</option>
                    {loading ? coordinators.map(coordinator => { return <option value={coordinator.id} key={coordinator.id}>{coordinator.first_name} {coordinator.last_name}</option>; }) : null}
                </Form.Control>
            </Form.Group>
            </div>);
        }
        setCoordinatorsDivs(rows);
    }

    return <div>
        {prop.coordinators ? <h2>Coördinatoren</h2> : null}
        <fieldset disabled={prop.isReadOnly}>
        {coordinatorsDivs}
        {prop.editable ? <Button onClick={() => renderContactAdd(1)}>Coordinator koppelen</Button> : null}
        </fieldset>
    </div>
}