import React, { useState, useEffect } from 'react';
import axios from 'axios';
import displayToast from '../helperFunctions/toasts';
import Paper from '@material-ui/core/Paper';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    WeekView,
    AllDayPanel,
    Toolbar,
    DateNavigator,
    Appointments,
    AppointmentTooltip,
    AppointmentForm,
    DragDropProvider,
    EditRecurrenceMenu,
    TodayButton,
    ViewSwitcher,
    MonthView,
    DayView,
    Resources,
    ConfirmationDialog,
} from '@devexpress/dx-react-scheduler-material-ui';
import { appointments } from './appointments';
import niceConfirm from "../helperFunctions/confirmPopup"
import "./Schedule.scss"
export default function Schedule(props) {
    const [addedAppointment, setAddedAppointment] = useState({});
    const [appointmentChanges, setAppointmentChanges] = useState({});
    const [editingAppointment, setEditingAppointment] = useState(undefined);
    const [isShiftPressed, setShiftPressed] = useState(false);
    const [appointmentData, setAppointmentData] = useState([]);
    const [docenten, setDocenten] = useState([]);
    const [school, setSchool] = useState([]);
    let [popup, setPopup] = useState(null);

    const scheduleUrl = process.env.REACT_APP_API_URL + "/scholen/schedule/";
    let currDate = new Date();
    currDate = currDate.getFullYear() + "-" + ("0" + currDate.getMonth()).slice(-2) + "-" + ("0" + currDate.getDay()).slice(-2);

    const SHIFT_KEY = 16;
    const allowDrag = ({ id }) => (!props.readOnly && props.userType === 'A');

    const resources = props.schoolID ? [
        {
            fieldName: "docenten",
            title: "Docenten",
            allowMultiple: true,
            instances: docenten
        }] : [{
            fieldName: "school",
            title: "School",
            instances: school
        }]


    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        if (!props.schoolID)
            getScholen();
        else
            getDocenten();
        getAppointments();
    }, [])

    function onKeyDown(event) {
        if (event.keyCode === SHIFT_KEY) {
            setShiftPressed(true);
        }
    }

    function onKeyUp(event) {
        if (event.keyCode === SHIFT_KEY) {
            setShiftPressed(false);
        }
    }

    async function getDocenten() {
        // gekoppelde_scholen__school: schid
        if (props.getDocenten) { props.getDocenten(0, 0, props.schoolID ? { "koppel_scholen__active": true, "koppel_scholen__school_id": props.schoolID } : null).then((res) => { setDocenten(res.data.map(el => ({ ...el, text: (el.user__first_name + " " + el.user__last_name) }))) }); }
    }

    async function getScholen() {
        // pass props: docenten_link__docent: docid
        if (props.getScholen) { props.getScholen(0, 0).then((res) => { setSchool(res.data.map(el => ({ ...el, text: el.name }))) }) }
    }

    function displayErrs(error) {
        if (error?.response?.data?.non_field_errors?.includes("Overlap with existing event")) {
            displayToast("Kan de les niet inplannen, deze docent heeft al een andere les op dit tijdstip.");
            return;
        }

        displayToast("Kon de veranderingen niet opslaan, probeer het later opnieuw.");
        console.log(error);
    }

    async function getAppointments() {
        let query = new URLSearchParams();
        let str = window.location.pathname.split("/");
        if (str[1] === "school") {
            query.append("school__id", str[2]);
        } else if (str[1] === "docent") {
            query.append("docenten__id", str[2]);

        } else {
            query.append("docenten__id", props.data.id);
        }
        let processedquery = {};
        for (let q of query.keys()) processedquery[q] = query.get(q);
        await axios.get(scheduleUrl, { withCredentials: true, params: processedquery })
            .then(function (res) {
                setAppointmentData(res.data);
            })
            .catch(function (error) {
                let errors = JSON.stringify(error?.response?.data);
                displayToast("Kon geen gegevens ophalen, probeer het later opnieuw.");
            })
    }

    async function addAppointment(changingdata, added, duplicate = -1) {
        if (props.readOnly) return;
        if (duplicate > 0) {
            const changedAppointment = changingdata.data.find(appointment => duplicate == appointment.id);
            added = { ...changedAppointment, ...added };
        }
        if (!added.docenten.filter(x => x).length) {
            displayToast("Een les moet ten minste één docent hebben.")
            return;
        }
        if (props.schoolID) added["school"] = props.schoolID; else added["docenten"] = [props.docentID];
        await axios.post(scheduleUrl, added, { withCredentials: true })
            .then(function (res) {
                changingdata.data = [...changingdata.data, res.data];
            })
            .catch(displayErrs);
    }

    async function changeAppointment(changingdata, changed) {
        if (props.readOnly) return;
        for (let x in changed) {
            if (changed[x].docenten?.filter(x => x)?.length < 1) {
                displayToast("Een les moet ten minste één docent hebben.")
                return;
            }
        }
        const changedAppointment = changingdata.data.find(appointment => changed[appointment.id]);
        await axios.post(scheduleUrl + changedAppointment.id, changed[changedAppointment.id], { withCredentials: true })
            .then(function (res) {
                changingdata.data = changingdata.data.map(appointment => (
                    changed[appointment.id]
                        ? { ...appointment, ...changed[appointment.id] }
                        : appointment));
            })
            .catch(displayErrs);
    }

    async function deleteAppointment(changingdata, deleted) {
        if (props.readOnly) return;
        await axios.delete(scheduleUrl + deleted, { withCredentials: true })
            .then(function (res) {
                changingdata.data = changingdata.data.filter(appointment => appointment.id !== deleted);
            })
            .catch(function (error) {
                displayToast("Kon de veranderingen niet opslaan, probeer het later opnieuw.");
            })
    }

    async function commitChanges({ added, changed, deleted }) {
        var changingdata = { data: appointmentData };
        if (deleted !== undefined) {
            await deleteAppointment(changingdata, deleted);
        }
        if (changed) {
            if (isShiftPressed) {
                for (let k in changed)
                    await addAppointment(changingdata, changed[k], k); // Send id of 

            } else {
                await changeAppointment(changingdata, changed); // Send id of appointment and changes

            }
        }
        if (added) {
            await addAppointment(changingdata, added);
        }
        setAppointmentData(changingdata.data);
    }

    return (<Paper>
        <Scheduler
            data={appointmentData}
            height={660}
            firstDayOfWeek={1} //Monday is first day of Week
        >
            <ViewState
                defaultCurrentViewName="Week"
            />

            <EditingState
                onCommitChanges={commitChanges}

                addedAppointment={addedAppointment}
                onAddedAppointmentChange={setAddedAppointment}

                appointmentChanges={appointmentChanges}
                onAppointmentChangesChange={setAppointmentChanges}

                editingAppointment={editingAppointment}
                onEditingAppointmentChange={setEditingAppointment}
            />
            <EditRecurrenceMenu />

            <DayView
                startDayHour={7}
                endDayHour={19}
            />
            <WeekView
                startDayHour={7}
                endDayHour={19}
            />
            <MonthView />
            <AllDayPanel />
            <Toolbar />
            <DateNavigator />
            <TodayButton />
            <ViewSwitcher />
            <Appointments />
            {props.readOnly ? null : <ConfirmationDialog />}
            <AppointmentTooltip
                showCloseButton
                showOpenButton={!props.readOnly}
                showDeleteButton={!props.readOnly}
            />
            {props.readOnly ? null : <AppointmentForm readOnly={props.readOnly}
            />}
            <DragDropProvider
                allowDrag={allowDrag}
            />

            <Resources
                data={resources}
                mainResourceName={props.schoolID ? "docenten" : "school"}
            />
        </Scheduler>
        {popup}
    </Paper>
    )
}