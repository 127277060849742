import react, { useState } from "react";
import Nav from 'react-bootstrap/Nav';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { HiChevronDown } from 'react-icons/hi';
import displayToast from '../helperFunctions/toasts';
import { useSelector, useDispatch } from "react-redux";
import { updateUserInfo } from "../redux/userSlice";
import "./ProfileBar.scss";

function ProfileBar() {
    const [dropDownUser, setdropDownUser] = useState(true);
    const history = useHistory();
    const userInfo = useSelector((state) => state.user.user);
    const dispatch = useDispatch();

    async function logout(event) {
        event.preventDefault();
        await axios.get(process.env.REACT_APP_API_URL + '/scholen/logout', { withCredentials: true }).then(function (response) {
            if (response.status === 200) {
                displayToast('Log-out succesvol, redirecting naar log-in!', 'success', history, '/login');
                dispatch(updateUserInfo(null));
            }
            else
                displayToast(`Uitloggen afgewezen door server. Statuscode: ${response.status}`)
        })
            .catch(function (error) {
                displayToast(`Server onbereikbaar. ${error})`);
            });
    }

    return (
        <div className="profile-bar-container">
            {userInfo ? <div>
                <Nav.Link onClick={() => { setdropDownUser(!dropDownUser) }}>Welkom {userInfo.first_name}<HiChevronDown /></Nav.Link>
                <div className="dropdown-container-user" style={{ display: (dropDownUser ? "none" : "block") }} id="dropdown">
                    <Nav.Link className="dropdown-container-user-item" onClick={logout}>Log uit</Nav.Link>
                </div>
            </div> : null}
        </div>
    )
}

export default ProfileBar;