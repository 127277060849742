import {
    configureStore,
    combineReducers
} from "@reduxjs/toolkit";
import userSlice from "./userSlice";


export default configureStore({
    reducer: {
        user: userSlice
    }
});;