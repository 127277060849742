import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import {
  FilteringState,
  IntegratedFiltering, EditingState, GroupingState,
  IntegratedGrouping, PagingState,
  CustomPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableFilterRow,
  TableGroupRow, PagingPanel,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import "./List.scss";
// import { loadMessages } from '@devexpress/dx-react-grid/localization';
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router';

const getRowId = row => row.id;


export default function List(props) {
  const [filterprops, setFilterprops] = useState(props.filterprops ? props.filterprops : {});
  const [sortlist, setSortlist] = useState(props.sortlist ? props.sortlist : []);
  const [rows, setRows] = useState([]);
  const [filteringStateColumnExtensions] = useState(props.columnExtensions);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageSizes] = useState([25, 50, 100]);
  const [currentPage, setCurrentPage] = useState(0);
  const [column, setColumn] = useState((typeof props.column == "function") ? [] : props.column);
  const refreshData = () => {
    props.getData(
      pageSize,
      currentPage * pageSize,
      props.preventfilteronserver ? {} :
        {
          ...filterprops,
          ...(props.include ? { include: props.include } : {}),
          ...(sortlist ? { sort: sortlist } : {}),
          ...(props.fields ? { fields: props.fields } : {})
        }
    ).then((res) => { setTotalCount(res.data.count); setRows(res.data.results); })
  };

  useEffect(() => {
    if (typeof props.column == "function") Promise.resolve(props.column()).then(res => setColumn(res));
    else setColumn(props.column);
  }, [props.column]);

  useEffect(refreshData, [currentPage, pageSize, props.getData, filterprops, sortlist]);
  const history = useHistory();
  const editColumnMessages = {
    addCommand: 'Voeg toe',
    editCommand: 'Wijzigen',
    deleteCommand: 'Open',
    commitCommand: 'Verstuur',
    cancelCommand: 'Annuleer',
  };

  // const TableHeaderContent = ({ column, children, ...restProps }) => (
  //   <TableHeaderRow.Content
  //     column={column}
  //     {...restProps}
  //   >
  //     {column.name === column[Object.keys(column)[0]] ? (
  //       <button
  //         type="button"
  //         className="btn btn-outline-secondary"
  //         // style={{ margin: '-7px 5px' }}
  //         // eslint-disable-next-line no-alert
  //         onClick={() => alert('Custom action')}
  //       >
  //        Voeg toe
  //       </button>
  //     ) : null}
  //     {children}
  //   </TableHeaderRow.Content>
  // );


  const commitChanges = ({ added, changed, deleted: opened }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    // Inline-changes toepassen.
    if (changed) {
      changedRows = props.row.map(row =>
        (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      async function postDocent() {
        await axios.post(`http://127.0.0.1:8000/scholen/docent/edit/${changed[1].user.id}`, changed[1], { withCredentials: true, })
          .then(function (response) {
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      postDocent();
    }
    if (opened) {
      history.push(`/${props.listName}/${opened}`);
    }
    // setRows(changedRows);
  };

  return (<div>
    <Grid
      columns={column}
      getRowId={props.getRowId ? props.getRowId : getRowId}
      rows={rows}
    >
      <PagingState
        currentPage={currentPage}
        onCurrentPageChange={setCurrentPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
      />
      <CustomPaging
        totalCount={totalCount}
      />
      <SortingState
        defaultSorting={[{ columnName: props.sorting_column, direction: props.sorting_direction }]}
        onSortingChange={(colls) => {
          let s = []; for (var i = colls.length - 1; i >= 0; i--) s.push((colls[i].direction === 'desc' ? "-" : "") + colls[i].columnName);
          setSortlist(s);
        }}
        columnExtensions={filteringStateColumnExtensions}
      />
      <IntegratedSorting />
      <FilteringState
        defaultFilters={props.defaultFilter ? props.defaultFilter : []}
        onFiltersChange={(colls) => {
          let f = {}; for (var i = colls.length - 1; i >= 0; i--) f[colls[i].columnName] = colls[i].value;
          setFilterprops(f);
        }}
        columnExtensions={filteringStateColumnExtensions}
      />
      <IntegratedFiltering />

      {props.grouping_column ? <><GroupingState
        grouping={[{ columnName: props.grouping_column }]}
      />
        <IntegratedGrouping /> </> : null}

      <Table />
      <TableHeaderRow showSortingControls className="bmo-red"
      // contentComponent={TableHeaderContent}
      />
      <PagingPanel pageSizes={pageSizes} />
      {props.grouping_column ?
        <TableGroupRow /> : null}

      <TableFilterRow />

      <EditingState
        columnExtensions={filteringStateColumnExtensions}
        onCommitChanges={commitChanges}
      />
      <TableEditRow />
      <TableEditColumn
        showDeleteCommand={props.listName ? true : false}
        messages={editColumnMessages}
      />
    </Grid>
  </div>)
}

