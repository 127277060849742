import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import displayToast from '../../helperFunctions/toasts';

export default function SchoolDocenten(prop) {
    return <div>
        {prop.all_docenten && prop.all_docenten.length > 0 ? prop.all_docenten.map((docenten_link, i) => {
            return (
                <div key={i}>
                    <h3><a href={`/docent/${docenten_link.docent}`}>{docenten_link.first_name} {docenten_link.last_name}</a></h3>
                    <p><span className="font-weight-bold">Rol:</span> {docenten_link.role ? docenten_link.role : "Onbekend"}</p>
                    <p><span className="font-weight-bold">Email:</span> {docenten_link.email ? docenten_link.email : "Onbekend"}</p>
                    {prop.isAdmin ? <p><span className="font-weight-bold">Uurloon:</span> {docenten_link.uurloon ? docenten_link.uurloon : "Onbekend"}</p> : null}
                </div>
            )
        }) : <div><p>Geen gekoppelde docenten</p></div>}
    </div>
}