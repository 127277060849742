import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "./ListTitle.scss";
function ListTitle(props) {



    return (
        <div className="list-wrapper">
            <h2>{props.title}</h2>
            { props.redirect ? 
                <Link to={props.redirect} className="btn btn-primary">+</Link>
                : null }
        </div>
    )
}

export default ListTitle;