import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import CanvasDraw from "react-canvas-draw";
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import displayToast from '../../helperFunctions/toasts';
import { Row } from 'react-bootstrap';
import { formatDateTime } from '../../helperFunctions/general';

export default function DocentScreeningInfo({ register, ...prop }) {
    let isEditable = !(window.location.pathname.match(/docent\/\d/));
    const [handtekening, setHandtekening] = useState(false);
    let [contracts, setContracts] = useState();
    let [contractList, setContractList] = useState([]);
    let [contractView, setContractView] = useState(null);
    let [wages_mr, setWages_mr] = useState([]);
    let [wages_zr, setWages_zr] = useState([]);
    let [uploadContract, setUploadContract] = useState(true);
    let [vog, setVog] = useState();
    const canvasRef = useRef(null);

    async function getContractList() {
        let wages_mr_temp = [];
        let wages_zr_temp = [];
        for (let i in contracts) {
            let contract = contracts[i].naam.split(" ");
            if (contract[2] === "ZR") {
                wages_zr_temp.push({ id: contracts[i].id, wage: contract[1] });
            } else if (contract[2] === "MR") {
                wages_mr_temp.push({ id: contracts[i].id, wage: contract[1] });

            }
        }
        setWages_zr(wages_zr_temp);
        setWages_mr(wages_mr_temp);
    }
    useEffect(() => {
        getAllDefaultContracts();
    }, []);

    useEffect(() => {
        renderContractChoice();
    }, [contracts, contractView]);

    async function renderContractChoice() {
        await getContractList();
        let contract_list = [<option key={"no_contract"}>Geen contract</option>];
        if (contractView === "MR" && wages_mr.length > 0) {
            wages_mr.map((contract, key) => {
                contract_list.push(
                    <option key={key} value={contract.id}>&#8364;{contract.wage}</option>
                )
            })
        } else if (contractView === "ZR" && wages_zr.length > 0) {
            wages_zr.map((contract, key) => {
                contract_list.push(
                    <option key={key} value={contract.id}>&#8364;{contract.wage}</option>
                )
            })
        } else {
            contract_list.push(
                <option>Geen contracten beschikbaar.</option>
            )
        }
        setContractList(contract_list);
    }

    async function getAllDefaultContracts() {
        await axios.get(`${process.env.REACT_APP_API_URL}/scholen/docent/contracten`, { withCredentials: true })
            .then(function (response) {
                setContracts(response.data);
                return response.data;
            })
            .catch((error, response) => {
                let errors = JSON.stringify(error.response.data);
                if (prop.userType !== 'D') displayToast(`${errors}\n Server onbereikbaar. ${error})`);
            })
    }

    return <div>
        <fieldset disabled={prop.isReadOnly}>
            {prop.userType === 'D' && prop.contract && !prop.contract_signed ? <><h3>Contract</h3><iframe src={prop.contract} width="100%" height="500px"></iframe>
                {prop.userType === 'D' && prop.contract ? <Form.Group controlId="formBasicEmail" className={isEditable && prop.userType === 'D' ? 'required' : null}><Form.Check
                    type="checkbox"
                    id="bevestig-contract"
                    className="mb-2"
                    label="Ik heb het bovenstaande contract en de algemene voorwaarden gelezen en ik ga akkoord."
                    required={true}
                />
                    <Form.Label className="control-label">Plaats je handtekening door in het vakje hieronder te tekenen</Form.Label>
                    <CanvasDraw ref={canvasRef} onChange={() => { if (!handtekening) setHandtekening(true); prop.setSaveHandtekening(canvasRef.current.getSaveData()) }} brushColor='black' backgroundColor='white' style={{ border: "1px solid #272727" }} hideGrid brushRadius={2} lazyRadius={0} canvasHeight={200} />
                    <Button
                        onClick={() => {
                            canvasRef.current.clear();
                            setHandtekening(false);
                        }}
                    >
                        Wis
                    </Button>
                </Form.Group> : null} </> : null}

            <Form.Row>

                <Form.Group className="profile-docent-form-contract">
                    <Form.Label><p><strong>Contract</strong></p></Form.Label>
                    {prop.contract ? <p>
                        <a href={prop.contract} target="_blank">Contract openen</a>
                    </p> : <p>Geen bestand geüpload.</p>}

                    {prop.userType === 'D' ? null : <>

                        {uploadContract ? <Row> <Col>
                            <Form.Label><strong>Contract type:</strong></Form.Label>
                            <Form.Control defaultValue="" onChange={(e) => { setContractView(e.target.value) }} as="select" >
                                <option selected={true} disabled={true} value="">Kies...</option>
                                <option value="MR">Met reiskosten</option>
                                <option value="ZR">Zonder reiskosten</option>
                            </Form.Control></Col>
                            <Col>
                                {contractView ? <>
                                    <Form.Label><strong>Uurloon:</strong></Form.Label>
                                    <Form.Control ref={register} as="select" name="defaultContract">
                                        {contractList}
                                    </Form.Control> </> : null}</Col>
                        </Row> : <Form.File ref={register} id="exampleFormControlFile1" name="contract" accept=".pdf" />}
                        {prop.contract ? <Form.Label><p> <i>Contract selecteren vervangt het huidige contract indien aanwezig</i></p></Form.Label> : null}
                        <Form.Row><Button style={{ marginLeft: "5px" }} onClick={() => setUploadContract(!uploadContract)}> {uploadContract ? "Contract uploaden" : "Contract kiezen"}</Button></Form.Row>
                    </>
                    }
                </Form.Group>
            </Form.Row>
        {
            prop.userType === 'D' ? <Form.Row>
                <Form.Group className={(isEditable && prop.userType === 'D' ? 'required ' : "") + "profile-docent-form-contract"}>
                    <Form.Label className="control-label"><strong>ID Voorkant upload</strong></Form.Label>
                    {prop.id_verwerkt === "Verwerkt" ? <><p>
                        ID Succesvol verwerkt!
                    </p> </> : <><p>Geen bestand geüpload.</p> <Form.File data-displayname="Voorkant ID" ref={register} id="exampleFormControlFile1" name="id_front" accept="image/*" required={prop.userType === 'D' ? true : false} /></>}


                </Form.Group>
                <Form.Group className={(isEditable && prop.userType === 'D' ? 'required ' : "") + "profile-docent-form-contract"}>
                    <Form.Label className="control-label"><strong>ID Achterkant upload</strong></Form.Label>
                    {prop.id_verwerkt === "Verwerkt" ? <><p>
                        ID Succesvol verwerkt!
                    </p> </> : <><p>Geen bestand geüpload.</p> <Form.File data-displayname="Achterkant ID" ref={register} id="exampleFormControlFile1" name="id_back" accept="image/*" required={prop.userType === 'D' ? true : false} /></>}


                </Form.Group>
            </Form.Row> : null
        }
        <Form.Row>
            <Form.Group className="profile-docent-form-contract">
                <Form.Label><p><strong>VOG betalingsbewijs</strong></p></Form.Label>
                {prop.vog_bewijs ? <p>
                    <a href={prop.vog_bewijs} target="_blank">VOG betalingsbewijs openen</a>
                </p> : <>
                    <Form.File ref={register} id="exampleFormControlFile1" name="vog_bewijs" accept=".pdf" />
                    <Form.Text className="text-muted">
                        {prop.userType === 'D' ? "Upload hier je betalingsbewijs PDF voor het aanvragen van je VOG." : "Geen bestand geüpload."}
                    </Form.Text></>}


                {prop.userType === 'D' ? null : (prop.vog_verstuurd
                    ? <> <br /> <Form.Group >
                        <p><strong>VOG aangevraagd: </strong>{prop.vog_verstuurd}</p>
                    </Form.Group></>
                    : <> <br /> <Form.Group >
                        <Form.Check ref={register} aria-label="option 1" label="VOG aangevraagd" defaultValue={formatDateTime(new Date(), "Y-M-D")} name="vog_verstuurd" />
                    </Form.Group></>)}
            </Form.Group>
            <Form.Group className="profile-docent-form-contract">
                <Form.Label><p><strong>VOG</strong></p></Form.Label>
                {prop.vog ? <p>
                    <a href={prop.vog} target="_blank">VOG openen</a>
                </p> : <>
                    <Form.File ref={register} id="exampleFormControlFile1" name="vog" accept=".pdf" />
                    <Form.Text className="text-muted">
                        {prop.userType === 'D' ? "Ben je al in het bezit van een geldige VOG, upload deze dan hier." : "Geen bestand geüpload."}
                    </Form.Text></>}
            </Form.Group>
        </Form.Row>
        </fieldset>
    </div >
}
