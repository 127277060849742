import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../redux/userSlice";
import 'react-toastify/dist/ReactToastify.css';
import displayToast from '../helperFunctions/toasts';
import "./Login.scss";


export default function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.user.user);
    const continue_urls = { 'A': `/docenten-bms`, 'D': `/me` };

    useEffect(() => {
        if (userInfo && userInfo !== "loading") {
            if (!userInfo.has_usable_password) history.push("/change-password");
            else history.push(continue_urls[userInfo.user_type]);
        }
    }, [userInfo])


    async function handleSubmit(event) {
        event.preventDefault();
        await axios.post(process.env.REACT_APP_API_URL + '/scholen/login', {
            email: email,
            password: password
        }, { withCredentials: true })
            .then(function (response) {
                if (response.status === 200) {
                    dispatch(updateUserInfo(response.data));
                    history.push(continue_urls[response.data.user_type])
                }
                else displayToast(`Log-in afgewezen door server. Statuscode: ${response.status}`)
            })
            .catch(function (error) {
                if (error.response && error.response.status === 400) {
                    displayToast(`Verkeerde inloggegevens`)
                } else
                    displayToast(`Server onbereikbaar. ${error})`);
            });
    }
    function validateForm() {
        return email.length > 0 && password.length > 0;
    }
    return <div className="login-wrapper">
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>E-mailadres</Form.Label>
                <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <Form.Text className="text-muted">
                    We zullen nooit je e-mailadres met derden delen.
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Wachtwoord</Form.Label>
                <Form.Control type="password" placeholder="Wachtwoord" value={password}
                    onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
            <p><a href={process.env.REACT_APP_API_URL + '/inschrijven/wachtwoord_vergeten/'} target="_blank">Wachtwoord vergeten?</a></p>
            <Button variant="primary" type="submit" disabled={!validateForm()}>
                Log in
  </Button>
        </Form>
    </div>
}