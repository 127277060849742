import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import displayToast from '../helperFunctions/toasts';
import {
    useHistory
} from 'react-router-dom';
import './ChangePassword.scss';
export default function ChangePassword() {
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    function submitChangePassword() {
        if (password && password !== confirmPassword) return displayToast("Password does not match!");
        axios.post(`${process.env.REACT_APP_API_URL}/scholen/docent/password`, { 'password': password, 'password2': confirmPassword }, { withCredentials: true }).then(function (res) {
            if (res.status === 200) displayToast('Wachtwoord opgeslagen, u wordt doorverwezen naar /me', 'success', history, `/me`, 2000);
            else displayToast(`Upload afgewezen door server. Statuscode: ${res.status}`)
        }).catch((error, response) => {
            let errors = JSON.stringify(error.response);
            displayToast(`${errors}\n Server onbereikbaar. ${error})`);
        })
    }
    return <div className="change-password-wrapper">
        <Container>
            <h2>Kies wachtwoord</h2>
            <Form.Group controlId="formBasicPassword">
                <Form.Label>Wachtwoord</Form.Label>
                <Form.Control type="password" placeholder="Wachtwoord" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
                <Form.Label>Bevestig wachtwoord</Form.Label>
                <Form.Control type="password" placeholder="Wachtwoord" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                {password !== confirmPassword ? <p style={{ color: 'red' }}>Wachtwoorden komen niet overeen</p> : null}
            </Form.Group>
            <Button onClick={submitChangePassword}>Opslaan</Button>
        </Container>
    </div >
}