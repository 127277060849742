import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import displayToast from '../helperFunctions/toasts';
import mailIcon from "../../assets/images/icons/mail_black.svg";
import phoneIcon from "../../assets/images/icons/phone_black.svg";
import { InputGroup } from 'react-bootstrap';

export default function Add(props) {
    let [phoneNum, setPhoneNum] = useState(0);
    let [mailNum, setMailNum] = useState(0);

    let newUserID = "";

    let number = props.number ? props.number : "";


    async function add(event) {
        event.preventDefault();
        let phone_numbers = [];
        let mail_addrs = [];
        let form = new FormData(event.target);
        if (form.get("phone_numbers[]")) {
            for (let i = 0; i < document.getElementsByName("phone_numbers[]").length; i++) {
                phone_numbers.push(document.getElementsByName("phone_numbers[]")[i].value)
            }
            form.append("phone_numbers", JSON.stringify(phone_numbers));
        } else form.append("phone_numbers", JSON.stringify([]));

        if (form.get("mail_addrs[]")) {
            for (let i = 0; i < document.getElementsByName("mail_addrs[]").length; i++) {
                mail_addrs.push(document.getElementsByName("mail_addrs[]")[i].value)
            }
            form.append("mail_addrs", JSON.stringify(mail_addrs));
        } else form.append("mail_addrs", JSON.stringify([]));

        await axios.post(`${process.env.REACT_APP_API_URL}/scholen/contact/new`, form, { withCredentials: true }).then(function (response) {
            if (response.status === 200) {
                newUserID = response.data.id;
                displayToast('Contactpersoon toegevoegd!', 'success');
                props.contactChanged ? props.setContactChanged(false) : props.setContactChanged(true);
                if (props.onAddedContact) props.onAddedContact(newUserID);
            }
            else
                displayToast(`Upload afgewezen door server. Statuscode: ${response.status}`)
        }).catch((error, response) => {
            let errors = JSON.stringify(error.response.data);
            displayToast(`${errors}\n Server onbereikbaar. ${error})`);
        })
    }

    function renderMailAdd() {
        let rows = [];
        for (var i = 0; i < mailNum; i++) {
            // note: we are adding a key prop here to allow react to uniquely identify each
            // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
            rows.push(<Col>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>E-mailadres</Form.Label>
                    <Form.Control type="email" placeholder="Voer e-mailadres in" name={`mail_addrs[${number}][]`} maxLength={254} />
                </Form.Group>
            </Col>);
        }
        return <React.Fragment>{rows}</React.Fragment>;
    }

    function renderPhoneAdd() {
        let rows = [];
        for (var i = 0; i < phoneNum; i++) {
            // note: we are adding a key prop here to allow react to uniquely identify each
            // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
            rows.push(<Col>
                <Form.Group controlId="formPhone">
                    <Form.Label>Telefoonnummer</Form.Label>
                    <Form.Control type="phone" placeholder="Voer Telefoonnummer in" name={`phone_numbers[${number}][]`} maxLength={20} />
                </Form.Group>
            </Col>);
        }
        return <React.Fragment>{rows}</React.Fragment>;
    }

    return <div className="profile-wrapper form-background">
        {/*<Form onSubmit={add} onInvalid={handleInvalidForm} onKeyDown={(e) => {
            if (e.nativeEvent.key == "Enter" && e.target.type !== "textarea") {
                e.preventDefault();
                const form = e.target.form;
                const index = Array.prototype.indexOf.call(form, e.target);
                form.elements[index + 1].focus();
            }
        }}>*/}
        {/* <Container> */}
        <div>
            <Form.Control type="hidden" name="contactnumber[]" value={number} />
            <Form.Control type="hidden" name={`contactID[${number}]`} value={props && props.person ? props.person.id : ""} />
            <Form.Row>
                <Col xs={1.5}>
                    <Form.Group controlId="formGeslacht">
                        <Form.Label>Geslacht</Form.Label>
                        <Form.Control data-displayname="Geslacht" as="select" required={true} name={`geslacht[${number}]`} defaultValue={props && props.person ? props.person.geslacht : ""}>
                            <option disabled={true} value="">Kies...</option>
                            <option value="M">Man</option>
                            <option value="F">Vrouw</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formFirstName">
                        <Form.Label>Voornaam</Form.Label>
                        <Form.Control type="text" placeholder="Voornaam" name={`first_name[${number}]`} defaultValue={props && props.person ? props.person.first_name : ""} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formLastName">
                        <Form.Label>Achternaam</Form.Label>
                        <Form.Control data-displayname="Achternaam" type="text" placeholder="Achternaam" name={`last_name[${number}]`} required={true} defaultValue={props && props.person ? props.person.last_name : ""} />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group controlId="formFunctie">
                        <Form.Label>Functie</Form.Label>
                        <Form.Control data-displayname="Functie" type="text" required={false} name={`functie[${number}]`} placeholder="Directeur" defaultValue={props && props.person ? props.person.functie : ""} />
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                {props && props.person && props.person.mail_addrs ?
                    props.person.mail_addrs.map((addr) => {
                        return (
                            <Col>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>E-mailadres</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                                <a href={`mailto:${addr}`}>
                                                    <img height="20px" width="20px" src={mailIcon} alt="mailIcon" />
                                                </a>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control data-displayname="E-mailadres" type="email" placeholder="E-mailadres" name={`mail_addrs[${number}][]`} maxLength={254} required={true} defaultValue={addr} />
                                    </InputGroup>
                                </Form.Group>
                            </Col>);
                    }) :
                    <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>E-mailadres</Form.Label>
                            <Form.Control data-displayname="E-mailadres" type="email" placeholder="E-mailadres" name={`mail_addrs[${number}][]`} maxLength={254} required={true} defaultValue="" />
                        </Form.Group>
                    </Col>}
                {renderMailAdd()}
            </Form.Row>
            <Form.Row>
                {props && props.person && props.person.phone_numbers ?
                    props.person.phone_numbers.map((telnr) => {
                        return (
                            <Col>
                                <Form.Group controlId="formPhone">
                                    <Form.Label>Telefoonnummer</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                                <a href={`tel:${telnr}`}>
                                                    <img height="20px" width="20px" src={phoneIcon} alt="phoneIcon" />
                                                </a>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control data-displayname="Telefoonnummer" type="phone" placeholder="Telefoonnummer" name={`phone_numbers[${number}][]`} maxLength={20} required={true} defaultValue={telnr} />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        );
                    }) :
                    <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Telefoonnummer</Form.Label>
                            <Form.Control data-displayname="Telefoonnummer" type="phone" placeholder="Telefoonnummer" name={`phone_numbers[${number}][]`} maxLength={20} required={true} defaultValue="" />
                        </Form.Group>
                    </Col>}
                {renderPhoneAdd()}
            </Form.Row>
            <Button className="btn-contact btn-blue" onClick={() => setMailNum(mailNum + 1)}>E-mailadres toevoegen</Button>
            <Button className="btn-contact btn-blue" onClick={() => setPhoneNum(phoneNum + 1)}>Telefoonnummer toevoegen</Button>
            {props.deleteBtn}
            <br />
            {true ? null : <><Form.Check
                type="checkbox"
                id="bevestig"
                className="mb-2"
                label="Bevestig nieuwe contact persoon"
                required={true}
            />
                <Button type="submit" className="mb-2">
                    Opslaan
                </Button></>}
        </div>
        {/*</Form>*/}
        {/* </Container> */}
    </div>
}