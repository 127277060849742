import React, { useState } from 'react';
import axios from 'axios';
import { Route, Redirect } from 'react-router-dom';
import { getUserInfo } from "../helperFunctions/autoSignIn";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, ...rest }) => {
  const userInfo = useSelector((state) => state.user.user);
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (userInfo && userInfo !== "loading") ? (
          children
        ) : (userInfo && userInfo === "loading") || (userInfo === null) ? <> </> : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );

};
export default PrivateRoute;